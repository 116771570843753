exports.components = {
  "component---src-components-templates-column-post-index-js": () => import("./../../../src/components/templates/column-post/index.js" /* webpackChunkName: "component---src-components-templates-column-post-index-js" */),
  "component---src-components-templates-news-post-index-js": () => import("./../../../src/components/templates/news-post/index.js" /* webpackChunkName: "component---src-components-templates-news-post-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-column-js": () => import("./../../../src/pages/column.js" /* webpackChunkName: "component---src-pages-column-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-document-js": () => import("./../../../src/pages/document.js" /* webpackChunkName: "component---src-pages-document-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-r-review-apply-js": () => import("./../../../src/pages/r-review/apply.js" /* webpackChunkName: "component---src-pages-r-review-apply-js" */),
  "component---src-pages-r-review-contact-js": () => import("./../../../src/pages/r-review/contact.js" /* webpackChunkName: "component---src-pages-r-review-contact-js" */),
  "component---src-pages-r-review-index-js": () => import("./../../../src/pages/r-review/index.js" /* webpackChunkName: "component---src-pages-r-review-index-js" */),
  "component---src-pages-r-review-terms-js": () => import("./../../../src/pages/r-review/terms.js" /* webpackChunkName: "component---src-pages-r-review-terms-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */)
}

